import React from 'react';
import '../App.css';

import firebase from "../components/Firestore";
import AddCombatant from "../components/AddCombatant"
import StatusConditions from "../components/StatusConditions"
import Combatant from "../components/Combatant"

const db = firebase.firestore();

class Combat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      round: null,
      turn: null,
      addCombantntVisible: false,
      statusConditionsVisible: false,
      addCombatantData: {},
      statusConditionConfig: {
        id: null,
        combatant: {},
        conditions: []
      },
      combatants: []
    };
  }

  updateViewDay = (day) => { this.setState({ viewDay: day }) }
  updateViewMonth = (date) => { this.setState({ viewMonth: date }) }

  setTurn = (turn_position) => {
    db.collection("combat").doc("config").update({ turn: turn_position });
  }

  nextTurn = () => {
    let activeCombatants = this.state.combatants.filter(com => { return com.active });

    let currentTurnIndex = activeCombatants.findIndex((combatant)=> {
      let cpos = isNaN((parseFloat(combatant.position))) ? combatant.initiative || 0 : combatant.position;
      return cpos === this.state.turn
    });

    let opt = currentTurnIndex === (activeCombatants.length -1) ? { round: this.state.round + 1 } : {}
    let nextCombatant = currentTurnIndex === (activeCombatants.length -1) ? activeCombatants[0] : activeCombatants[currentTurnIndex + 1];
    let npos = isNaN((parseFloat(nextCombatant.position))) ? nextCombatant.initiative || 0 : nextCombatant.position;

    db.collection("combat").doc("config").update(Object.assign({}, opt, { turn: npos }));
  }

  prevTurn = () => {
    let activeCombatants = this.state.combatants.filter(com => { return com.active });

    let currentTurnIndex = activeCombatants.findIndex((combatant)=> {
      let cpos = isNaN((parseFloat(combatant.position))) ? combatant.initiative || 0 : combatant.position;
      return cpos === this.state.turn
    });

    let opt = {};
    let prevCombatant = activeCombatants[currentTurnIndex];
    if(currentTurnIndex === 0) {
      opt = (this.state.round - 1 > 0) ? { round: this.state.round - 1 } : {}
      prevCombatant = this.state.round === 1 ? prevCombatant : activeCombatants[(activeCombatants.length -1)]
    } else { prevCombatant = activeCombatants[currentTurnIndex - 1] }
    let ppos = isNaN((parseFloat(prevCombatant.position))) ? prevCombatant.initiative || 0 : prevCombatant.position;

    db.collection("combat").doc("config").update(Object.assign({}, opt, { turn: ppos }));
  }

  resetEncounter = () => {
    if(window.confirm('Are you sure you want to reset the encouter?')) {
      db.collection("combat").doc("config").collection("combatants").get().then((data) => {
        data.docs.forEach(doc => { doc.ref.delete(); })
      });
      db.collection("combat").doc("config").update({ turn: null, round: 1 });
    }
  }


  toggleStatusesModal = (val) => { this.setState({ statusConditionsVisible: val }); }
  editCombatantStatuses = (data) => {
    let conditions = (typeof data.conditions == 'object') && !Array.isArray(data.conditions) ? Object.keys(data.conditions).map(k => data.conditions[k]) : [];
    let combatant = { position: data.position, name: data.name, id: data.id }
    this.setState({ statusConditionsVisible: true, statusConditionConfig: { id: data.id, combatant: combatant, conditions: conditions } });
  }
  toggleAddCombatantModal = (val) => { this.setState({ addCombantntVisible: val }); }
  editCombatant = (data) => { this.setState({ addCombantntVisible: true, addCombatantData: { id: data.id, name: data.name, initiative: (data.initiative || 0) } }); }
  newCombatant = () => { this.setState({ addCombantntVisible: true, addCombatantData: { name: '', initiative: '', id: '' } }); }

  updateCombatantPosition = (data, cid) => {
    let dhigh = 9999999;
    let dlow = 0;
    let dint = isNaN(parseInt(data.initiative)) ? 0 : parseInt(data.initiative);

    this.state.combatants.forEach((combatant) => {
      let cpos = isNaN(parseFloat(combatant.position)) ? 0 : parseFloat(combatant.position);
      let cint = isNaN(parseInt(combatant.initiative)) ? 0 : parseInt(combatant.initiative);

      if(cid !== combatant.id) {
        if(cint >= dint) {
          if(dlow > cpos) { dlow = cpos - 2 }
          if(dhigh > cpos) { dhigh = cpos - 1 }
        }
        else {
          if(dlow < cpos) { dlow = cpos + 1 }
          if(dhigh < cpos) { dhigh = cpos + 2 }
        }
      }
    })

    return Math.random() * (dhigh - dlow + 1) + dlow
  }

  addFireStoreCombatant = (combatant) => {
    combatant.position = this.updateCombatantPosition(combatant, null);
    db.collection("combat").doc('config').collection('combatants').add(Object.assign({}, { initiative: 0 }, combatant));
  }

  removeFireStoreCombatant = (combatant_id, combatant) => {
    if(window.confirm('Are you sure you want to remove ' + combatant.name + ' from this encounter?')) {
      db.collection("combat").doc('config').collection('combatants').doc(combatant_id+'').delete();
    }
  }

  updateFireStoreCombatant = (combatant_id, combatant) => {
    let combatantIndex = this.state.combatants.findIndex((c)=> { return c.id === combatant_id });
    let combatantState = this.state.combatants[combatantIndex];
    combatantState = Object.assign({}, { initiative: 0 }, combatantState)

    let cpos = isNaN(parseFloat(combatantState.position)) ? (combatantState.initiative || 0) : combatantState.position;
    let updateTurn = this.state.turn === cpos;

    let newCombatantState = Object.assign({}, combatantState, combatant);
    let skiptToNextTurn = updateTurn && !newCombatantState.active;

    newCombatantState.position = this.updateCombatantPosition(newCombatantState, combatant_id);
    db.collection("combat").doc('config').collection('combatants').doc(combatant_id+'').set(newCombatantState);
    if(skiptToNextTurn) { this.nextTurn() }
    else if(updateTurn) { this.setTurn(newCombatantState.position) }
  }

  updateFireStoreCombatantConditions = (combatant_id, conditions) => {
    let combatantIndex = this.state.combatants.findIndex((c)=> { return c.id === combatant_id });
    let combatantState = this.state.combatants[combatantIndex];

    combatantState = Object.assign({}, { initiative: 0 }, combatantState);
    combatantState.conditions = Object.assign({}, conditions);
    // combatantState.conditions = Object.assign({}, combatantState.conditions, conditions);

    db.collection("combat").doc('config').collection('combatants').doc(combatant_id+'').set(combatantState);
    this.setState({
      statusConditionsVisible: false,
      statusConditionConfig: {
        id: null,
        combatant: {},
        conditions: []
      },
    })
  }

  sortCombatants = (combatantsAry) => {
    combatantsAry.sort((a,b)=>{
      let asort = a.hasOwnProperty('position') ? a.position : a.initiative;
      let bsort = b.hasOwnProperty('position') ? b.position : b.initiative;
      return bsort - asort
    })
    return combatantsAry
  }

  retreiveCombatants = () => {
    const appFunctions = { setState: (data) => { this.setState(data) }, sortCombatants: (ary) => { this.sortCombatants(ary) } }

    let combatantsAry = []
    db.collection('combat').doc('config').collection('combatants').onSnapshot(querySnapshot => {
      querySnapshot.docChanges().forEach(change => {
        const combatantIndex = combatantsAry.findIndex((combatant)=> { return combatant.id === change.doc.id });
        if(combatantIndex > -1) {
          if(change.type === 'removed') { combatantsAry.splice(combatantIndex, 1); }
          else {
            combatantsAry[combatantIndex] = Object.assign({}, change.doc.data(), { id: change.doc.id })
          }
        } else {
          combatantsAry.push(Object.assign({}, change.doc.data(), { id: change.doc.id }))
        }
      })

      appFunctions.sortCombatants(combatantsAry)
      appFunctions.setState({ combatants: combatantsAry });
    })
  }

  retreiveFireStoreDate = () => {
    const appFunctions = {
      retreiveCombatants: this.retreiveCombatants,
      setState: (data) => { this.setState(data) }
    }

    db.collection("combat").doc("config").onSnapshot(function(doc) {
      let data = doc.data();
      appFunctions.retreiveCombatants();
      let round = [null, undefined, 0, '0'].includes(data.round) ? 1 : data.round;
      appFunctions.setState({ round: round, turn: data.turn })
    });
  }

  Combatants = () => {
    const rootStuff = {
      editCombatant: this.editCombatant,
      setTurn: this.setTurn,
      removeFireStoreCombatant: this.removeFireStoreCombatant,
      updateFireStoreCombatant: this.updateFireStoreCombatant,
      editCombatantStatuses: this.editCombatantStatuses,
      combatants: this.state.combatants,
      currentCombatant: this.currentCombatant()
    }
    return(
      <div className="combatants-holder">
        {this.state.combatants.map((combatant, idx) => {
          return(<Combatant key={idx} {...rootStuff} {...combatant} round={this.state.round} turn={this.state.turn} ></Combatant>)
        })}
      </div>
    )
  }

  currentCombatant = () => {
    let currentTurnIndex = this.state.combatants.findIndex((combatant)=> {
      let cpos = isNaN((parseFloat(combatant.position))) ? combatant.initiative || 0 : combatant.position;
      return cpos === this.state.turn
    });

    return this.state.combatants[currentTurnIndex]
  }

  AppViewModel = () => {
    if(this.state.round === null) {
      return (
        <div className="App">
          <div style={{ fontSize: '6vw' }}>Loading...</div>
        </div>
      )
    }
    else {
      const rootStuff = {
        toggleAddCombatantModal: this.toggleAddCombatantModal,
        editCombatantStatuses: this.editCombatantStatuses,
        addFireStoreCombatant: this.addFireStoreCombatant,
        updateFireStoreCombatant: this.updateFireStoreCombatant,
        updateFireStoreCombatantConditions: this.updateFireStoreCombatantConditions,
        toggleStatusesModal: this.toggleStatusesModal,
        round: this.state.round,
        turn: this.state.turn,
        combatants: this.state.combatants,
        currentCombatant: this.currentCombatant()
      }
      return (
        <section className="App">
          <AddCombatant key="AddCombatantModal" visible={this.state.addCombantntVisible} combatantData={this.state.addCombatantData} {...rootStuff} />
          <StatusConditions key="StatusConditionsModal" visible={this.state.statusConditionsVisible} conditionConfig={this.state.statusConditionConfig} {...rootStuff} />
          <div className="overflow-hider top"></div>
          <div className="round-holder">
            <h2 className="round-title">Round: {this.state.round}</h2>
            <span className='display-flex'>
              <h2 className="round-clear-combatants pointer" onClick={()=>{ this.resetEncounter() }}>-</h2>
              <h2 className="round-add-combatant pointer" onClick={()=>{ this.newCombatant() }}>+</h2>
            </span>
          </div>
          <this.Combatants />
          <div className="turn-cta">
            <div className="prev-turn pointer noselect" onClick={()=>{ this.prevTurn() }}><h2>{"<"}</h2></div>
            <div className="control-type"><h2>Turn</h2></div>
            <div className="next-turn pointer noselect" onClick={()=>{ this.nextTurn() }}><h2>{">"}</h2></div>
          </div>
          <div className="overflow-hider bottom"></div>
        </section>
      );
    }
  }

  componentDidMount() { if(this.state.round === null) { this.retreiveFireStoreDate(); } }

  render() {
    return(
      this.AppViewModel()
    )
  }
}

export default Combat;
