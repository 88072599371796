import React, {useState, useEffect} from 'react';

const StatusConditions = (props) => {
  const [combatantId, setCombatantId] = useState('')
  const [conditions, setConditions] = useState([])
  const [round, setRound] = useState(1);
  const [turn, setTurn] = useState(1)

  const setConditionConfig = (condition) => {
    let affecteeTurn = parseFloat(props.conditionConfig.combatant.position);
    let fromTurn = parseFloat(condition.fromTurn);
    let fromRound = parseInt(condition.fromRound);
    let untilTurn = parseInt(condition.untilTurn);
    // let currentTurn = parseFloat(turn);
    // let currentRound = parseInt(round);
    let duration = parseInt(condition.durationCount);

    if(duration < 0) { condition.untilRound = -1 }
    else if(untilTurn > affecteeTurn) {
      if(fromTurn < affecteeTurn) { condition.untilRound = fromRound + (duration + 1) }
      else { condition.untilRound = fromRound + duration }
    }
    else if(untilTurn <= affecteeTurn) {
      if(fromTurn < affecteeTurn) { condition.untilRound = fromRound + duration }
      else { condition.untilRound = fromRound + (duration - 1) }
    }

    return condition
  }

  const saveConditions = (updateFireStoreCombatantConditions) => {
    let updatedConditions = conditions.map((condition) => {
      return setConditionConfig(condition)
    })

    updateFireStoreCombatantConditions(combatantId, updatedConditions)
  }

  const conditionDuration = (condition) => {
    let currentRound = parseInt(round);
    let currentTurn = parseFloat(turn);
    let fromRound = parseInt(condition.fromRound);
    let untilTurn = parseFloat(condition.untilTurn);
    let duration = parseInt(condition.durationCount);
    let displayDuration = null;


    if(duration < 0) { return "∞" }
    else if(condition.endScope.toLowerCase() === "startof") {
      let turnOrder = (currentTurn <= untilTurn) ? 0 : 1;
      displayDuration = duration - ((currentRound - fromRound) - turnOrder);
    }
    else if(condition.endScope.toLowerCase() === "endof") {
      let turnOrder = (currentTurn < untilTurn) ? 1 : 0;
      displayDuration = duration - ((currentRound - fromRound) + turnOrder);
    }

    return displayDuration;
  }

  const findConditionIndex = (condition) => {
    let conIdx = conditions.findIndex(con => {
      return (
        con.fromRound === condition.fromRound &&
        con.fromTurn === condition.fromTurn &&
        con.untilRound === condition.untilRound &&
        con.conditionDuration === condition.conditionDuration &&
        con.untilTurn === condition.untilTurn &&
        con.label === condition.label
      )
    })

    return conIdx;
  }

  const updateCondition = (condition, newState) => {
    const conIdx = findConditionIndex(condition);
    conditions[conIdx] = newState;
    setConditions(conditions.map(x=>x))
  }

  const endCondition = (condition) => {
    updateCondition(condition, Object.assign({}, condition, { untilRound: round, untilTurn: turn, endScope: 'startof'}));
  }

  const removeCondition = (condition) => {
    if(window.confirm(`Are you sure you want to remove the ${condition.label} condition? This will remove it from the condition history.`)) {
      const conIdx = findConditionIndex(condition);
      conditions.splice(conIdx, 1);
      setConditions(conditions.map(x=>x))
    }
  }

  const changeConditionLabel = (condition, val) => {
    updateCondition(condition, Object.assign({}, condition, { label: val }));
  }

  const increaseDuration = (condition) => {
    if(condition.durationCount === -1) { condition.durationCount = 1 }
    else if(condition.durationCount > 0) { condition.durationCount = condition.durationCount + 1 }
    return condition
  }

  const decreaseDuration = (condition) => {
    if(condition.durationCount === -1) { return condition }
    else if(condition.durationCount > 0) {
      if((condition.durationCount - 1) > 0) { condition.durationCount = condition.durationCount - 1 }
      else { condition.durationCount = -1 }
    }

    return condition
  }

  const showCondition = (con) => {
    let currentRound = parseInt(round);
    let currentTurn = parseInt(turn);
    let fromTurn = parseFloat(con.fromTurn);
    let fromRound = parseInt(con.fromRound);
    let untilTurn = parseFloat(con.untilTurn);
    let untilRound = parseInt(con.untilRound);
    // if this is the round we set it
    if(fromRound === currentRound) {
      // and is gereater or equal to the turn we set it
      if(fromTurn >= currentTurn) { return true }
    }

    // if the set round is less than the current round
    else if(fromRound < currentRound) {
      // and the until round is equal to this round
      if(untilRound === currentRound) {
        if(untilTurn === currentTurn) { return con.endScope === 'startof' ? false : true; }
        else if(untilTurn < currentTurn) { return true }
      }

      // and the until round is greater than this round
      else if(untilRound > currentRound) { return true }
    }

    return false
  }

  const conditionScopeOptions = () => {
    return props.combatants.map((com) => {
      return (<option key={com.id + com.name} value={com.position}>{com.name}'s Turn</option>)
    })
  }

  const Condition = (condition, combatantId, setConditions, idx) => {
    if(!showCondition(condition)) { return null }
    return (
      <div className='condition-row' key={"combatantCondition" + idx}>
        <span className="pointer noselect" onClick={()=>{ removeCondition(condition) }}>x</span>
        <input
          className="combatant-status"
          type="text"
          name="combatant_status"
          placeholder="Condition"
          defaultValue={condition.label}
          onChange={(e)=>{ changeConditionLabel(condition, e.target.value) }}
        />
        <div className='condition-controlls condition-clickers'>
          <span onClick={()=>{updateCondition(condition, decreaseDuration(condition))}} className="pointer noselect condition-decrease">-</span>
          <span className="condition-duration noselect">{conditionDuration(condition)}</span>
          <span onClick={()=>{updateCondition(condition, increaseDuration(condition))}} className="pointer noselect condition-increase">+</span>
        </div>
        <select name="endscope" defaultValue={condition.endScope} onChange={(e)=> { updateCondition(condition, Object.assign({}, condition, { endScope: e.target.value })) }}>
          <option value="startof">Start of</option>
          <option value="endof">End of</option>
        </select>
        <select name="untilTurn" defaultValue={condition.untilTurn} onChange={(e)=> { updateCondition(condition, Object.assign({}, condition, { untilTurn: e.target.value })) }}>
          {conditionScopeOptions()}
        </select>
        {/* <div onClick={()=>{ endCondition(condition) }} className='condition-controlls end-condition pointer'>
          <span>End</span>
        </div> */}
      </div>
    )
  }

  const Conditions = (combatantId, setConditions) => {
    if(!conditions || conditions.length < 0) { return null }
    return conditions.map((condition, idx) => {
      return Condition(condition, combatantId, setConditions, idx)
    })
  }

  const addCondition = (con, setConditions) => {
    conditions.push({
      label: '',
      fromRound: round,
      fromTurn: turn,
      untilRound: -1,
      durationCount: -1,
      // untilTurn: props.conditionConfig.combatant.position
      untilTurn: props.conditionConfig.combatant.position,
      endScope: 'endof'
    })

    setConditions(conditions.map(x=>x))
  }

  const StatusesModal = (toggleStatusesModal, updateFireStoreCombatantConditions) => {
    return (
      <section className="overlay">
        <div className="modal">
          <div className="close pointer red-background" onClick={()=>{ toggleStatusesModal(false); }}></div>
          <div className="flex-row">
            <div className="display-flex conditions-heading">
              <span className='conditions-modal-label pointer' onClick={()=>{ console.log(conditions) }}>Conditions</span>
              <span className='add-condition pointer' onClick={()=>{ addCondition(conditions, setConditions) }}>+</span>
            </div>
          </div>
          { Conditions(combatantId, setConditions) }
          <div className="save-status-conditions">
            <span onClick={()=>{ saveConditions(updateFireStoreCombatantConditions) }} className='button green-background pointer'>Save Conditions</span>
          </div>
        </div>
      </section>
    )
  }

  useEffect(() => {
    if(props.visible) {
      props.conditionConfig.id ? setCombatantId(props.conditionConfig.id) : setCombatantId('')
      props.conditionConfig.conditions ? setConditions(props.conditionConfig.conditions) : setConditions('')
      props.round ? setRound(props.round) : setRound('')
      props.turn ? setTurn(props.turn) : setTurn('')
    }
  }, [props.visible, props.conditionConfig.id, props.conditionConfig.conditions, props.round, props.turn])

  // Render
  return props.visible ? StatusesModal(props.toggleStatusesModal, props.updateFireStoreCombatantConditions) : null;
}

export default StatusConditions;
