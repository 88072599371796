import React from 'react';

let conditionLexicon = {
  'blinded': { color: '#000000', icon: null },
  'charmed': { color: '#de0bad', icon: null },
  'deadly poison': { color: '#02ab05', icon: null },
  'deafened': { color: '#5c5c5c', icon: null },
  'frightened': { color: '#695b00', icon: null },
  'grappled': { color: '#0e0c8a', icon: null },
  'incapacitated': { color: '#59252e', icon: null },
  'invisible': { color: '#365770', icon: null },
  'petrified': { color: '#5c5c5c', icon: null },
  'poisoned': { color: '#218a23', icon: null },
  'prone': { color: '#5c5c5c', icon: null },
  'rage': { color: '#f50018', icon: null },
  'restrained': { color: '#61115c', icon: null },
  'stunned': { color: '#876c09', icon: null },
  'unconscious': { color: '#3d628f', icon: null }
}

let defaultConfig = { color: '#5c5c5c', icon: null }

let Condition = (con) => {
  let lexiconConfig = conditionLexicon[con.label.toLowerCase()];
  let conditionConfig = lexiconConfig ? lexiconConfig : defaultConfig;

  // const Duration = () => {
  //   // console.log(con)
  //   // return null
  //   let affecteeTurn = parseFloat(con.props.position);
  //   let currentRound = parseInt(con.props.round);
  //   let currentTurn = parseFloat(con.props.turn);
  //   let fromTurn = parseFloat(con.fromTurn);
  //   let fromRound = parseInt(con.fromRound);
  //   let untilTurn = parseInt(con.untilTurn);
  //   let duration = parseInt(con.durationCount);
  //   let displayDuration = null;
  //   let roundOffset = currentRound - fromRound;
  //   let durationOffset = duration - roundOffset;
  //   let displayOffset = fromRound === currentRound ? -1 : 0;

  //   if(duration < 0) { return "∞" }
  //   else if(untilTurn > affecteeTurn) {
  //     if(fromTurn < affecteeTurn) { displayDuration = fromRound + durationOffset }
  //     else { displayDuration = fromRound + (durationOffset + displayOffset) }
  //   }
  //   else if(untilTurn <= affecteeTurn) {
  //     if(fromTurn < affecteeTurn) { displayDuration = fromRound + (durationOffset + displayOffset) }
  //     else { displayDuration = fromRound + (durationOffset - (displayOffset + 1)) }
  //   }

  //   return ": " + displayDuration;
  // }

  const Duration = () => {
    let currentRound = parseInt(con.props.round);
    let currentTurn = parseFloat(con.props.turn);
    let fromRound = parseInt(con.fromRound);
    let untilTurn = parseFloat(con.untilTurn);
    let duration = parseInt(con.durationCount);
    let displayDuration = null;


    if(duration < 0) { return "∞" }
    else if(con.endScope.toLowerCase() === "startof") {
      let turnOrder = (currentTurn <= untilTurn) ? 0 : 1;
      displayDuration = duration - ((currentRound - fromRound) - turnOrder);
    }
    else if(con.endScope.toLowerCase() === "endof") {
      let turnOrder = (currentTurn < untilTurn) ? 1 : 0;
      displayDuration = duration - ((currentRound - fromRound) + turnOrder);
    }

    return ": " + displayDuration;
  }

  let ConditionLabel = () => {
    return (<span className="condition-label">{con.label}</span>)
  }

  let ConditionStatus = () => {
    if(con.visible) {
      return (
        <div className="condition" style={{ color: conditionConfig.color }}>
          <ConditionLabel />
          <Duration />
        </div>
      )
    }
    return null
  }

  return (
    <ConditionStatus />
  )
}

export default Condition;
