import React from 'react';
import { Swipeable } from 'react-swipeable';
import Condition from './Condition'

let Combatant = (props) => {
  let swipeableConfig = {
    delta: 10,                             // min distance(px) before a swipe starts
    preventDefaultTouchmoveEvent: false,   // preventDefault on touchmove, *See Details*
    trackTouch: true,                      // track touch input
    trackMouse: true,                     // track mouse input
    rotationAngle: 0                       // set a rotation angle
  }

  let swipeHandler = (evt) => {
    // switch(evt.dir) {
    //   case 'Up':
    //   case 'Left':
    //     props.root.advanceDimensionDays();
    //     break;
    //   case 'Down':
    //   case 'Right':
    //     props.root.reduceDimensionDays();
    //     break;
    //   default:
    //     break;
    // }
  }

  const showCombatant = () => {
    if(props.hidden) {
      if(parseInt(props.reveal_on_round) > 0) {
        if(parseInt(props.round) > parseInt(props.reveal_on_round)) { return true }
        else if(parseInt(props.round) === parseInt(props.reveal_on_round) && parseFloat(props.turn) <= parseFloat(props.position)) { return true }
        else { return false }
      }
      else { return false }
    }
    else { return true }
  }

  const combatantShown = showCombatant()

  const isTurn = (props) => {
    if(props.active && combatantShown) {
      return parseFloat(props.turn) === parseFloat(props.position)
    }

    return false
  }

  const isInactive = (props) => { return !props.active }

  const showCondition = (con) => {
    // if this is the round we set it
    if(parseInt(con.fromRound) === parseInt(props.round)) {
      // and is gereater or equal to the turn we set it
      if(parseFloat(con.fromTurn) >= parseFloat(props.turn)) { return true }
    }

    // if the set round is less than the current round
    else if(parseInt(con.fromRound) < parseInt(props.round)) {
      // and the until round is equal to this round
      if(con.untilRound === parseInt(props.round)) {
        if(parseFloat(con.untilTurn) === parseFloat(props.turn)) { return con.endScope === 'startof' ? false : true; }
        else if(parseFloat(con.untilTurn) < parseFloat(props.turn)) { return true }
      }

      // and the until round is greater than this round
      else if(parseInt(con.untilRound) > parseInt(props.round)) { return true }
    }

    return false
  }

  const visibleConditions = () => {
    if(typeof props.conditions === 'undefined') { return [] }
    let conditions = Object.keys(props.conditions).map((k) => { return props.conditions[k] });
    return conditions.filter((con)=>{
      return showCondition(con)
    })
  }

  const Conditions = () => {
    const shownConditions = visibleConditions()
    if(shownConditions.length > 0 ) {
      return (
        <div className="combatant-statuses">
          {shownConditions.map((condition, idx) => {
            return (<Condition key={idx} visible={showCondition(condition)} props={{...props}} {...condition} />)
          })}
        </div>
      )
    }
    else { return null }
  }

  const toggleCombatant = (props) => {
    props.updateFireStoreCombatant(props.id, { active: !props.active })
  }

  const setNewTurn = (props) => {
    if(props.active) { props.setTurn(props.position || props.initiative) }
  }

  const CombtantantRow = () => {
    return (
      <section className="combatant-holder">
        <div className={ ["combatant", isTurn(props) ? "is-turn" : "", isInactive(props) ? "is-inactive" : "", props.color].join(' ') }>
          <div className="initiative pointer" onClick={()=>{ props.editCombatantStatuses(props) }}>{props.initiative}</div>
          <Swipeable className="combatant-info" onSwiped={(eventData) => { swipeHandler(eventData) }} {...swipeableConfig}>
            <div className="combatant-name">
              <span className="pointer" onClick={()=>{ props.editCombatant(props) }}>{props.name}</span>
              <span className='display-flex'>
                <span className={["remove-combatant", "pointer", props.color].join(' ')} onClick={()=>{ props.removeFireStoreCombatant(props.id, props) }}>-</span>
                <span className={["remove-combatant", "pointer", props.color].join(' ')} onClick={()=>{ toggleCombatant(props) }}>x.x</span>
                <span className={["turn-toggle", "pointer", props.color].join(' ')} onClick={()=>{ setNewTurn(props) }}></span>
              </span>
            </div>
            <Conditions />
          </Swipeable>
        </div>
      </section>
    )
  }

  const CombatantHolder = () => {
    if(combatantShown) { return ( <CombtantantRow /> ) }
    else { return null }
  }

  return (
    <CombatantHolder />
  );
}

export default Combatant;
