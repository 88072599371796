import React, {useState, useEffect} from 'react';

const AddCombatant = (props) => {
  let [combatantName, setCombatantName] = useState('')
  let [initiative, setInitiative] = useState('')

  const addCombatant = (clr, toggleAddCombatantModal) => {
    if(props.combatantData && props.combatantData.id) {
      props.updateFireStoreCombatant(props.combatantData.id, {
        name: document.getElementById('combatant_name').value,
        initiative: document.getElementById('combatant_initiative').value,
        color: clr,
        active: true
      });
      toggleAddCombatantModal(false);
    } else {
      props.addFireStoreCombatant({
        name: document.getElementById('combatant_name').value,
        initiative: document.getElementById('combatant_initiative').value,
        color: clr,
        active: true
      });
    }

    setCombatantName('');
    setInitiative('');
  }

  const handleUpdateCombatantName = (e)=> { setCombatantName(e.target.value); }
  const handleUpdateCombatantInitiative = (e)=> { setInitiative(e.target.value); }

  const AddCombatantModal = (toggleAddCombatantModal, combatantName, initiative, handleUpdateCombatantName, handleUpdateCombatantInitiative) => {
    return (
      <section className="overlay">
        <div className="modal">
          <div className="close pointer red-background" onClick={()=>{ toggleAddCombatantModal(false); }}></div>
          <div className="flex-row">
            <input
              className="combatant-info name"
              type="text" id="combatant_name"
              name="combatant_name"
              placeholder="Combatant Name"
              value={combatantName}
              key="combatantName"
              onChange={handleUpdateCombatantName}
            />
            <input
              className="combatant-info initiative"
              type="number" id="combatant_initiative"
              name="combatant_initiative"
              placeholder="Initiative"
              key="combatantInitiative"
              value={initiative}
              onChange={handleUpdateCombatantInitiative}
            />
          </div>
          <div className="flex-row">
            <div className="pointer swatch pink-background" onClick={()=>{ addCombatant('pink', toggleAddCombatantModal) }}></div>
            <div className="pointer swatch red-background" onClick={()=>{ addCombatant('red', toggleAddCombatantModal) }}></div>
            <div className="pointer swatch orange-background" onClick={()=>{ addCombatant('orange', toggleAddCombatantModal) }}></div>
            <div className="pointer swatch yellow-background" onClick={()=>{ addCombatant('yellow', toggleAddCombatantModal) }}></div>
            <div className="pointer swatch green-background" onClick={()=>{ addCombatant('green', toggleAddCombatantModal) }}></div>
            <div className="pointer swatch blue-background" onClick={()=>{ addCombatant('blue', toggleAddCombatantModal) }}></div>
            <div className="pointer swatch purple-background" onClick={()=>{ addCombatant('purple', toggleAddCombatantModal) }}></div>
            <div className="pointer swatch black-background" onClick={()=>{ addCombatant('black', toggleAddCombatantModal) }}></div>
            <div className="pointer swatch gray-background" onClick={()=>{ addCombatant('gray', toggleAddCombatantModal) }}></div>
          </div>
        </div>
      </section>
    )
  }

  useEffect(() => {
    if(props.visible) {
      props.combatantData.initiative ? setInitiative(props.combatantData.initiative) : setInitiative('')
      props.combatantData.name ? setCombatantName(props.combatantData.name) : setCombatantName('')
    }
  }, [props.visible, props.combatantData.name, props.combatantData.initiative])

  // Render
  return props.visible ? AddCombatantModal(props.toggleAddCombatantModal, combatantName, initiative, handleUpdateCombatantName, handleUpdateCombatantInitiative) : null;
}

export default AddCombatant;
